import { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useTelegramMessage } from "../../utils/request";
import "./MainForm.css";

const MainForm = () => {
  const [userName, setUserName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(false);
  const { sendTelegramMessage } = useTelegramMessage();


  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false);
  };

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const city = localStorage.getItem("city");

    let cityName;
    
    if (city === "lviv") {
      cityName = "Львів";
    } else if (city === "kyiv") {
      cityName = "Киев";
    } else {
      cityName = "Город не выбран";
    }
      
    let nameErrorFlag = false;
    let phoneErrorFlag = false;

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
    } else {
      setNameError(false);
    }
  

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      phoneErrorFlag = true;
    }


    if (nameErrorFlag || phoneErrorFlag) {
      return;
    }


    if (!nameErrorFlag && !phoneErrorFlag) {
      const message = `\u{1F408}ЗАЯВКА!!!\u{1F408}\nИмя: ${userName}\nНомер телефона: ${userPhone}\nГород: ${cityName}`;

      await sendTelegramMessage(message);
      

      setUserName("");
      setUserPhone("");
    }
  };

  return (
    <section className="main__form">
      <div className="main__form-wrapper">
        <h2 className="main__form-title mont-b">Залишились питання? </h2>
        <h3 className="main__form-description mont-b">
          Залишіть свої контакні данні і ми дамо відповіді на всі ваші
          запитання. Зателефонуємо вам протягом 5 хвилин!
        </h3>
        <form onSubmit={handleSubmit} className="main__form-form">
          <input
            placeholder="Ім’я"
            value={userName}
            onChange={handleNameChange}
            className={`main__form-input ${
              nameError ? "error" : ""
            } mont-b`}
            type="text"
          />
          <ReactInputMask
            mask="+380 (99) 999-99-99"
            value={userPhone}
            onChange={handlePhoneNumberChange}
            placeholder="Номер телефону"
            onFocus={(e) => e.target.setSelectionRange(6, 6)}
            onBlur={(e) => {
              if (e.target.value === "+380 ()") {
                setUserPhone("");
              }
            }}
            className={`main__form-input ${
              PhoneError ? "error" : ""
            } mont-b`}
            type="phone"
          />
          <button
            onSubmit={handleSubmit}
            className="main__form-button mont-b"
            type="submit"
          >
            Передзвоніть мені
          </button>
        </form>
      </div>
    </section>
  );
};

export default MainForm;
