import './Cover.css'
import coverImage from '../../assets/images/cover-tf.webp'

const Cover = ({city}) => {
  return (
    <section className="cover">
      <img src={coverImage} alt="обкладинка, на якій зображені запчастини" className="cover__image" />
      <div className="cover__fade"></div>
      <h1 className="cover__title mont-b">Професійний ремонт вашої техніки у {city === "kyiv" ? "Києві" : city === "lviv" ? "Львові" : "Києві"}</h1>
    </section>
  )
}

export default Cover