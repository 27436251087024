import React from "react";
import './CityModal.css'
import closeIcon from '../../assets/icons/plusIcon.png'

function CityModal({ onSelect, onClose }) {
  const handleCityClick = (city) => {
    onSelect(city);
  };

   const handlePropagation = (e) => {
    e.stopPropagation()
   }

  return (
    <div onClick={onClose} className="city__modal">
        <div onClick={handlePropagation} className="city__modal-body">
        <h2 className="city__modal-title mont-b">Оберіть Ваше місто</h2>
      <button className="city__modal-text mont-b" onClick={() => handleCityClick("kyiv")}>Київ</button>
      <button className="city__modal-text mont-b" onClick={() => handleCityClick("lviv")}>Львів</button>
      <img className="city__modal-close" src={closeIcon} alt="закрити модальне вікно вибору міста" onClick={onClose} />
        </div>
      
    </div>
  );
}

export default CityModal;
